import React, { useEffect, useState } from 'react';

import { apiUser } from './ApiCall';


export interface UserBiometryProps {
  imagePath: any
  imageSide: any
}

const UserBiometryImage = ({imagePath, imageSide}:UserBiometryProps) => {

  const [imageItem, setImageItem] = useState<any>();

  const getUsersBiometryImage = async() => {

    try {
      const response = await apiUser.post('/biometry/getimageByPath', 
        {
          path: imagePath
        }
      );
      console.log(response.data);
      setImageItem(response.data?.image || 'na')
    
    
    } catch (error) {
      console.error(error);
    }
    
    }


    let pacoca = 0
useEffect(() => {
  if(pacoca === 0){
    ++pacoca
    if(imagePath === '---'){
      pacoca=0
    }else{
      getUsersBiometryImage()
    }
  }
}, [])


return(
  <img
src={`data:image/png;base64,${imageItem}`}
alt={`Documento`}
className="document-image"
/>
)

};

export default UserBiometryImage;