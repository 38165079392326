import React, { useState } from 'react'
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  FloatingLabel,
  Dropdown,
  Table,
  Alert
} from 'react-bootstrap'

import { useForm } from 'react-hook-form'
import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'

registerLocale('pt-br', ptBR)

import 'react-datepicker/dist/react-datepicker.css'
import { fetchWithApiKey } from '../../config/apiCall'

const maskCPF = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

// (00) 00000-0000
const maskPhone = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

const strongPassword = (password: any) => {
  return /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
    password
  )
}

export function CreateApprover() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues,
    reset
  } = useForm()

  const [startDate, setStartDate] = useState(new Date())

  const [hasToken, setHasToken] = useState('')

  const [disableSign, setDisableSign] = useState(false)

  const [newUser, setNewUser] = useState({
    login: '',
    password: '',
    set: false
  })

  const onSubmit = (data: any) => {
    setDisableSign(true)

    fetchWithApiKey(`${BASE.api.base_url}${BASE.api.access_approver}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': BASE.api.api_key
      },
      body: JSON.stringify({
        name: data.name.replace(/^\s+|\s+$/g, ''),
        departament: data.departament.replace(/^\s+|\s+$/g, ''),
        role: data.type || 'default'
      })
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response)
        setNewUser({
          login: response.login,
          password: response.password,
          set: true
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const resetForm = () => {
    setNewUser({
      login: '',
      password: '',
      set: false
    })
    setHasToken('')
    setDisableSign(false)
    reset()
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h5">Cadastro de aprovadores</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="mb-3">
                <Col>
                  <Form.Label>Nome</Form.Label>
                  <Form.Control placeholder="Nome" {...register('name')} />
                </Col>

                <Col>
                  <Form.Label>Departamento</Form.Label>
                  <Form.Control
                    placeholder="Departamento"
                    {...register('departament')}
                  />
                  {errors.departament && (
                    <span className="label-error">
                      Você precisa informar um Departamento válido
                    </span>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Button
                      disabled={disableSign}
                      type="submit"
                      className="mb-0 w-100"
                      onClick={() => clearErrors()}
                    >
                      Criar Aprovador
                    </Button>
                  </Form.Group>
                </Col>
                <Col>
                  {disableSign && (
                    <Form.Group>
                      <Button
                        className="mb-0 btn-success w-100"
                        onClick={() => resetForm()}
                      >
                        Cadastrar Outro
                      </Button>
                    </Form.Group>
                  )}
                </Col>
              </Row>
              {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
            </Form>
          </Col>
        </Row>

        {newUser.set && (
          <>
            <hr />
            <Row>
              <Col className="qr-wrapper">
                <h3>Aprovador cadastrado com sucesso!</h3>
              </Col>
            </Row>
          </>
        )}
      </Card.Body>
    </Card>
  )
}
