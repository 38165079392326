import axios, { AxiosRequestConfig , InternalAxiosRequestConfig} from 'axios';

const sessionItem:any = window.sessionStorage.getItem('user-key')
const {user_key, tenant_id} = sessionItem ? JSON.parse(sessionItem): {user_key:null, tenant_id: null}

const apiCompany = axios.create({
  baseURL: 'https://service-company-563791290358.southamerica-east1.run.app',
  headers: {
    'x-api-key': user_key,
    'x-tenant-id': tenant_id
  }
});

const apiAuth = axios.create({
  baseURL: 'https://auth.bepass.app',
  headers: {
    'x-api-version': 'v2'
  }
});

const apiEvent = axios.create({
  baseURL: 'https://events.bepass.app',
  headers: {
    'x-api-key': user_key,
    'x-tenant-id': tenant_id
  }
});

const apiUser = axios.create({
  baseURL: 'https://users.bepass.app',
  headers: {
    'x-api-key': user_key,
    'x-tenant-id': tenant_id
  }
});

const apiJaiminho = axios.create({
  baseURL: 'xxxxx',
  headers: {
    'x-api-key': user_key,
    'x-tenant-id': tenant_id
  }
});

export { apiCompany, apiEvent, apiUser, apiJaiminho, apiAuth };