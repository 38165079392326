// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBpVxHFxhvgpvh1j50PzpQ704e2o1Gyib4",
  authDomain: "bepass-platform.firebaseapp.com",
  databaseURL: "https://bepass-platform-default-rtdb.firebaseio.com",
  projectId: "bepass-platform",
  storageBucket: "bepass-platform.appspot.com",
  messagingSenderId: "563791290358",
  appId: "1:563791290358:web:44193d43df4549a5caf1d7",
  measurementId: "G-3B8Q36W2S0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const appSet = getAuth(app);
appSet.tenantId = 'botafogo-lk5fp'
export const auth  = appSet;

