import React, { Component, useCallback, useEffect, useState } from 'react'
import {
  FormControl,
  Row,
  Col,
  Card,
  Tab,
  Tabs,
  Table,
  Form,
  InputGroup,
  Button
} from 'react-bootstrap'

import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'

import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'
import { useForm } from 'react-hook-form'

import './reports.sass'
import { fetchWithApiKey } from '../../config/apiCall'

function Reports() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues,
    reset
  } = useForm()

  const currentUser = sessionStorage.getItem('role')

  if (currentUser === 'default') {
    window.location.href = '/dashboard'
  }

  const [reportList, setReportList] = useState([])

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [disableSign, setDisableSign] = useState(false)

  const gates = [
    {
      name: 'Matarazzo',
      direction: 'Entraram',
      group: 'Catraca 1',
      IP: '192.168.0.168',
      sync: true
    },
    {
      name: 'Matarazzo',
      direction: 'Entraram',
      group: 'Catraca 2',
      IP: '192.168.0.171',
      sync: false
    },
    {
      name: 'Matarazzo',
      direction: 'Sairam',
      group: 'Catraca 1',
      IP: '192.168.0.169',
      sync: false
    },
    {
      name: 'Matarazzo',
      direction: 'Sairam',
      group: 'Catraca 2',
      IP: '192.168.0.172',
      sync: false
    },
    {
      name: 'Palestra',
      direction: 'Entraram',
      group: 'Catraca 1',
      IP: '192.168.0.174',
      sync: false
    },
    {
      name: 'Palestra',
      direction: 'Entraram',
      group: 'Catraca 2',
      IP: '192.168.0.177',
      sync: false
    },
    {
      name: 'Palestra',
      direction: 'Sairam',
      group: 'Catraca 1',
      IP: '192.168.0.175',
      sync: false
    },

    {
      name: 'Palestra',
      direction: 'Sairam',
      group: 'Catraca 2',
      IP: '192.168.0.178',
      sync: false
    },
    {
      name: 'Estacionamento',
      direction: 'Entraram',
      group: 'Catraca 1',
      IP: '192.168.0.180',
      sync: false
    },
    {
      name: 'Estacionamento',
      direction: 'Entraram',
      group: 'Catraca 2',
      IP: '192.168.0.183',
      sync: false
    },
    {
      name: 'Estacionamento',
      direction: 'Sairam',
      group: 'Catraca 1',
      IP: '192.168.0.181',
      sync: false
    },

    {
      name: 'Estacionamento',
      direction: 'Sairam',
      group: 'Catraca 2',
      IP: '192.168.0.184',
      sync: false
    },
    {
      name: 'Conveniência',
      direction: 'Entraram',
      group: 'Catraca 1',
      IP: '192.168.0.186',
      sync: false
    },
    {
      name: 'Conveniência',
      direction: 'Entraram',
      group: 'Catraca 2',
      IP: '192.168.0.189',
      sync: false
    },
    {
      name: 'Conveniência',
      direction: 'Sairam',
      group: 'Catraca 1',
      IP: '192.168.0.187',
      sync: false
    },

    {
      name: 'Conveniência',
      direction: 'Sairam',
      group: 'Catraca 2',
      IP: '192.168.0.190',
      sync: false
    }
  ]

  const findGate = (gateIP: any) => {
    const currentGate = gates.find((item) => item.IP === gateIP)
    return currentGate ? `${currentGate.name}` : '-'
  }

  // Get bellow info
  const allGateStatus = (reportFilter: any) => {
    fetchWithApiKey(`${BASE.api.base_url}${BASE.api.access_reports}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      },
      body: JSON.stringify(reportFilter)
    })
      .then((resg) => resg.json())
      .then((response) => {
        setReportList(response)
        setDisableSign(false)
      })
      .catch((error) => {
        console.log(error)
        setDisableSign(false)
      })
  }

  const dateConvert = (date: any) => {
    const eventDate = new Date(date)

    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }

    return `${eventDate.toLocaleDateString(
      'pt-br'
    )} ${eventDate.getHours()}:${eventDate.getMinutes()}:${eventDate.getSeconds()}`
  }

  const onSubmit = (data: any) => {
    const dateFormatePermanenceStart = new Date(startDate)
    const dateFormatePermanenceEnd = new Date(endDate)

    setDisableSign(true)

    allGateStatus({
      dateFrom: `${dateFormatePermanenceStart.getFullYear()}-${
        dateFormatePermanenceStart.getMonth() + 1
      }-${dateFormatePermanenceStart.getDate()}T00:00:00.000Z`,
      dateTo: `${dateFormatePermanenceEnd.getFullYear()}-${
        dateFormatePermanenceEnd.getMonth() + 1
      }-${dateFormatePermanenceEnd.getDate()}T23:59:00.000Z`,
      // type: 'associate',
      // externalId: 'false',
      // userId: 'e9a22c9d-89de-4f32-8e0d-a25eb8ab9aaf',
      gate: ['192.168.0.174']
    })
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h5">Pesquisar</Card.Title>
        <span className="d-block m-t-5">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Form.Label>Tipo de título</Form.Label>
              <Row>
                <Col sm={6} md={2}>
                  <Form.Check
                    type="checkbox"
                    id="visitor"
                    label="Visitante"
                    {...register('visitor')}
                  />
                </Col>
                <Col sm={6} md={2}>
                  <Form.Check
                    type="checkbox"
                    id="guest"
                    label="Convidado"
                    {...register('guest')}
                  />
                </Col>
                <Col sm={6} md={2}>
                  <Form.Check
                    type="checkbox"
                    id="associate"
                    label="Associado"
                    {...register('associate')}
                  />
                </Col>
                <Col sm={6} md={2}>
                  <Form.Check
                    type="checkbox"
                    id="provider"
                    label="Prestador"
                    {...register('provider')}
                  />
                </Col>
                <Col sm={6} md={2}>
                  <Form.Check
                    type="checkbox"
                    id="employee"
                    label="Funcionário"
                    {...register('employee')}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Row>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Título</Form.Label>
                  <FormControl
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                </Form.Group>
              </Col>

              <Col md={3}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Dispositivo</Form.Label>
                  <Form.Control as="select">
                    <option>Todos</option>
                    <option>Matarazzo</option>
                    <option>Palestra</option>
                    <option>Estacionamento</option>
                    <option>Conveniência</option>
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col md={3}>
                <Form.Group>
                  <Form.Label>Data de acesso:</Form.Label>
                  <DatePicker
                    className="form-control"
                    locale="pt-br"
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={(date: Date) => setStartDate(date)}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                {' '}
                <Form.Group>
                  <Form.Label>à:</Form.Label>
                  <DatePicker
                    className="form-control"
                    locale="pt-br"
                    dateFormat="dd/MM/yyyy"
                    selected={endDate}
                    onChange={(date: Date) => setEndDate(date)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={3}>
                {' '}
                <Form.Group>
                  <Button
                    disabled={disableSign}
                    type="submit"
                    className="mb-0 w-100"
                    onClick={() => clearErrors()}
                  >
                    Pesquisar
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </span>
      </Card.Header>
      <Card.Body>
        <Table responsive>
          <thead>
            <tr>
              <th>Data de acesso:</th>
              <th>Nome</th>
              <th>Título</th>
              <th>Dispositivo</th>
              <th>Acesso</th>
            </tr>
          </thead>
          <tbody>
            {reportList &&
              reportList.map((item: any) => (
                <tr key={item.id}>
                  <td>{dateConvert(item.eventTime)}</td>
                  <th scope="row">{item.name}</th>
                  <td>{item.externalId}</td>
                  <td>{findGate(item.gate)}</td>
                  <td>{item.direction === 'exit' ? 'Saída' : 'Entrada'}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default Reports
