import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap';

import qrCode from '../../assets/images/game_qr.png'

import { BASE } from '../../basedata/constants'
import { fetchWithApiKey } from '../../config/apiCall';

interface Props {
  onResponse: (response: any) => void
}

const QrCode = ({ onResponse }: Props) => {

  const [qrData, setQrData] = useState('')

  const ticketNumber = sessionStorage.getItem('ticket')
  const blankTicket = sessionStorage.getItem('role')
  const usedTicket = sessionStorage.getItem('usedTicket')

  const getQrCode = () => {
    fetchWithApiKey(`${BASE.ticket_api.base_url}${BASE.ticket_api.getQrCode}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': BASE.api.api_key
      },
      body: JSON.stringify({
        ticketNumber: ticketNumber
      })
    })
      .then((res) => res.json())
      .then((response) => {
        setQrData(response.qr)
        sessionStorage.removeItem('ticket')
        sessionStorage.removeItem('usedTicket')

      })
      .catch((error) => {
        alert('não foi possível gerar o ingresso')
        console.log(error)
      })
  }



  useEffect(() => {
    getQrCode()
  }, [])
  return (

    <Card>
      <Card.Header>
        <Card.Title as="h5"
          onClick={() => onResponse(0)}>
          <i className="feather icon-arrow-left"></i> Início
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <div className='game-qr'>

          <img src={qrData} />

        </div>
      </Card.Body>
    </Card>

  )
}

export default QrCode