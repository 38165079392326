import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap';

import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import userFrame from './../../assets/images/user-frame.svg'

import { BASE } from '../../basedata/constants'
import { fetchWithApiKey } from '../../config/apiCall';

interface Props {
  onResponse: (response: any) => void
}


const TakeFoto = ({ onResponse }: Props) => {
  function handleTakePhoto(dataUri: any) {
    
    const reason = sessionStorage.getItem('reason')
    const ticketNumber = sessionStorage.getItem('ticket')
    const usedTicket = sessionStorage.getItem('usedTicket')
    const blankTicket = sessionStorage.getItem('role')
    const token = sessionStorage.getItem('token')
    const gameCode = sessionStorage.getItem('gameCode')

    fetchWithApiKey(`${BASE.ticket_api.base_url}${BASE.ticket_api.generateLog}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': BASE.api.api_key
      },
      body: JSON.stringify({
        ticket: ticketNumber,
        operator: token,
        qrCode: ticketNumber,
        imageBase64: dataUri,
        reason: reason || 2,
        gameCode: gameCode || '00000',
        gameId: '4ed833ad-bf55-42e2-8847-ba09ef5b7295'
      })
    })
      .then((res) => res.json())
      .then((response) => {
        const reason = sessionStorage.removeItem('reason')
        const gameCode = sessionStorage.removeItem('gameCode')
        onResponse(2)
      })
      .catch((error) => {
        alert('não foi possível gerar o ingresso')
        console.log(error)
      })




  }

  return (

    <Card>
      <Card.Header>
        <Card.Title as="h5"
          onClick={() => onResponse(0)}>
          <i className="feather icon-arrow-left"></i> Voltar
        </Card.Title>
      </Card.Header>
      <Card.Body className='canvas-wrap'>
        <div className='canvas-frame'>
          {sessionStorage.getItem('reason') !== '5' && (
            <img src={userFrame} />
          )}
        </div>
        <Camera
          idealFacingMode="environment"
          onTakePhoto={(dataUri: any) => { handleTakePhoto(dataUri); }}
        />
      </Card.Body>
    </Card>


  )
}

export default TakeFoto